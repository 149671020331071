import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://50cf3266f6b441dbbcdb90c0dd8d863a@sentry.iproyal.com/33",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});